<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card  @addModal="addModal({ type: 'base' })" dialog heading="Matrizes de referência" :button="button" >
     <template>
        <v-card>
          <v-row
            class="pa-4"
            justify="space-between"
          >
            <v-col cols="4">
              <v-treeview
                :active.sync="active"
                :items="items"
                :load-children="getChildren"
                :open.sync="open"
                activatable
                return-object
                color="primary"
                transition
                shaped
                expand-icon="mdi-chevron-down"
                loading-icon="mdi-loading"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon v-if="!item.children">
                    mdi-file
                  </v-icon>
                </template>
                <template v-slot:append="{ item }">
                  <v-btn
                    icon
                    v-if="item.children && item.organization.id === id"
                    @click="addModal(item)"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
              </v-treeview>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col
              class="d-flex justify-center text-center"
              cols="8"
            >
              <v-scroll-y-transition mode="out-in">
                <div
                  v-if="!selected"
                  class="text-h6 grey--text text--lighten-1 font-weight-light"
                  style="align-self: center;"
                >
                  Selecione uma disciplina.
                </div>
                <v-card
                  v-else
                  :key="selected.id"
                  class="pt-6 mx-auto"
                  width="700"
                  flat
                >
                  <v-card-text>
                    <h3 class="text-h5 mb-2">
                      {{ selected.name }}
                    </h3>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-row
                    class="text-left ma-0 mt-4 pa-0"
                    tag="v-card-text"
                  >
                    <v-col
                      class="d-flex justify-start align-center ma-0 pr-2 pa-0"
                      tag="strong"
                      cols="3"
                    >
                      Disciplina:
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                        v-model="selected.name"
                        outlined
                        dense
                        background-color="transparent"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    class="text-left ma-0 pa-0"
                    tag="v-card-text"
                  >
                    <v-col
                      class="d-flex justify-start align-center ma-0 pr-2 pa-0"
                      tag="strong"
                      cols="3"
                    >
                      Sigla:
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                        v-model="selected.name_abbr"
                        outlined
                        dense
                        background-color="transparent"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      class="d-flex justify-center my-0 py-0"
                    >
                      <v-btn color="primary" v-if="selected.organization.id === id" @click="save(selected)">
                        <v-icon>mdi-content-save-outline</v-icon>
                        Salvar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-scroll-y-transition>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </base-card>
    <v-dialog v-model="dialog.show" max-width="800">
      <v-card>
        <v-card-title>Adicionar {{dialog.title}}</v-card-title>
        <v-card-text>
          <v-text-field
            label="Insira o nome"
            class="mb-4 mt-0"
            v-model="newData.name"
            hide-details
          ></v-text-field>
          <v-text-field
            label="Insira a sigla"
            class="my-0"
            v-model="newData.name_abbr"
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="create"
          >
            Criar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    active: [],
    dialog: {
      show: false,
      title: '',
      type: '',
      uri: '',
      item: {}
    },
    newData: {
      name: '',
      name_abbr: ''
    },
    items: [],
    teaching_stage: [],
    knowledge_area: [],
    curricular_component: [],
    avatar: null,
    open: [],
    fecthTS: false,
    page: {
      title: 'Avaliação'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Matrizes de Referencia',
        disabled: false,
        to: '/ReferenceMatrix'
      }
    ],
    button: {
      show: true,
      text: 'Etapa de ensino',
      icon: 'mdi-plus'
    }
  }),

  computed: {
    selected () {
      if (!this.active.length) return undefined

      var selected

      if (this.active[0].type === 'curricular-components') {
        const curricularComponentId = this.active[0].id
        const knowledgeAreaId = this.active[0].knowledge_area.id
        const teachingStageId = this.active[0].teaching_stage.id

        selected = this.items
          .find(teachingStage => teachingStage.id === teachingStageId).children
          .find(knowledgeArea => knowledgeArea.id === knowledgeAreaId).children
          .find(curricularComponent => curricularComponent.id === curricularComponentId)
      } else if (this.active[0].type === 'knowledge-areas') {
        const knowledgeAreaId = this.active[0].id
        const teachingStageId = this.active[0].teaching_stage.id

        selected = this.items
          .find(teachingStage => teachingStage.id === teachingStageId).children
          .find(knowledgeArea => knowledgeArea.id === knowledgeAreaId)
      } else {
        const teachingStageId = this.active[0].id

        selected = this.items
          .find(teachingStage => teachingStage.id === teachingStageId)
      }
      return selected
    }
  },

  methods: {
    formatItems (items, type, disableChildren) {
      const arr = []
      if (!items.length) return arr
      items.forEach((value, index) => {
        const obj = {
          ...value.data,
          id: value.metadata.id,
          type,
          ...(!disableChildren) && { children: [] }
        }
        arr.push(obj)
      })
      return arr
    },
    async getChildren (e) {
      const type = e.type
      const search = { path: '', typeChildren: '' }
      if (type === 'teaching-stages') {
        search.path = `teaching-stages/${e.id}/knowledge-areas`
        search.type = 'knowledge-areas'
      } else if (type === 'knowledge-areas') {
        search.path = `teaching-stages/${e.teaching_stage.id}/knowledge-areas/${e.id}/curricular-components`
        search.type = 'curricular-components'
      } else if (type === 'curricular-components') {
        search.path = `teaching-stages/${e.teaching_stage.id}/knowledge-areas/${e.knowledge_area.id}/curricular-components/${e.id}`
        search.type = 'curricular-component'
      }

      try {
        await this.$axios.get(`organizations/${this.id}/${search.path}`).then(response => {
          if (search.type === 'curricular-components') {
            e.children.push(...this.formatItems(response.data.items, search.type, true))
          } else {
            e.children.push(...this.formatItems(response.data.items, search.type))
          }
        })
      } catch (error) {
      }
    },
    async getItems (e) {
      const self = this
      try {
        await this.$axios.get(`organizations/${this.id}/teaching-stages`).then(response => {
          this.items = this.formatItems(response.data.items, 'teaching-stages')
        })
      } catch (error) {
        const errosArray = Object.assign({}, error.response.data.errors)
        if (error.response.data.errors) {
          Object.keys(errosArray).forEach(function (item) {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: errosArray[item][0]
            })
          })
        }
      } finally {
      }
    },
    async save (value) {
      const self = this
      const uri = value.type === 'curricular-components'
        ? `organizations/${this.id}/teaching-stages/${value.teaching_stage.id}/knowledge-areas/${value.knowledge_area.id}/curricular-components/${value.id}`
        : (value.type === 'knowledge-areas'
          ? `organizations/${this.id}/teaching-stages/${value.teaching_stage.id}/knowledge-areas/${value.id}`
          : `organizations/${this.id}/teaching-stages/${value.id}`)

      this.$axios.patch(uri,
        {
          name: value.name,
          name_abbr: value.name_abbr
        })
        .then(response => { self.$store.dispatch('alert', { msg: 'Dados salvos com sucesso. Em alguns instantes a mudança será completada.' }) })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(async () => {
          this.items = []
          await this.getItems()
        })
    },
    async create () {
      const self = this
      this.dialog.uri = this.dialog.item.type === 'base'
        ? `organizations/${this.id}/teaching-stages`
        : (this.dialog.item.type === 'teaching-stages'
          ? `organizations/${this.id}/teaching-stages/${this.dialog.item.id}/knowledge-areas`
          : (this.dialog.item.type === 'knowledge-areas'
            ? `organizations/${this.id}/teaching-stages/${this.dialog.item.teaching_stage.id}/knowledge-areas/${this.dialog.item.id}/curricular-components`
            : null))

      this.$axios.post(this.dialog.uri,
        {
          name: this.newData.name,
          name_abbr: this.newData.name_abbr
        })
        .then(response => { self.$store.dispatch('alert', { msg: 'Dados criados com sucesso.' }) })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(async () => {
          this.items = []
          await this.getItems()
        })

      this.newData = {
        name: '',
        name_abbr: ''
      }
      this.dialog.show = false
    },
    addModal (item) {
      this.dialog.show = true

      this.dialog.item = item
      this.dialog.title = item.type === 'base'
        ? 'Etapa de Ensino'
        : (item.type === 'teaching-stages'
          ? `Área de Conhecimento para ${item.name}`
          : (item.type === 'knowledge-areas'
            ? `Componente Curricular de ${item.name}`
            : 'undefined'))
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (this.id) {
      this.getItems()
    }
  }
}
</script>
